.header {
    background-color: #ffffff; /* White background */
    padding: 40px 20px;
  }
  
  .header-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1400px; /* Wider header */
    margin: 0;
  }
  
  .left-section {
    flex: 1;
    text-align: left; /* Align content to the left */
  }
  
  .small-logo {
    max-height: 30px;
    margin-bottom: 20px;
  }
  
  .logo-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    display: block;
    margin-bottom: 20px;
  }
  
  .headings {
    margin-top: 20px;
  }
  
  .heading {
    font-size: 2rem;
    color: #333;
    margin: 10px 0;
  }
  
  .heading:nth-child(2) {
    color: #777;
  }
  
  .heading:nth-child(3) {
    color: #000;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .header-content {
      flex-direction: column;
      text-align: left;
    }
  
    .left-section {
      width: 100%;
    }
  
    .headings {
      margin-top: 30px;
    }
  }
  