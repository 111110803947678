/* src/Views/HomePage.css */
.cafe-homepage {
    padding: 20px;
}

.homepage {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.hero-content {
    flex: 1;
    padding-right: 40px;
}

.hero-image {
    flex: 1;
    /* Add styling for the image */
}

.journey, .resume {
    width: 100%;
    margin-bottom: 40px;
}

.resume-item {
    display: flex;
    margin-bottom: 30px;
}

.company-logo {
    flex: 0 0 200px;
    margin-right: 20px;
    /* Add styling for the logo */
}

.resume-content {
    flex: 1;
}

h1, h2, h3, h4 {
    color: #333;
}

p {
    color: #666;
    line-height: 1.6;
}
